import React, { Component } from "react";

class About extends Component {
  state = {};
  render() {
    return (
      <div>
        <div>
          ForIT Norr AB is a consultant company focused on software development.
        </div>

        <div>
          We deliver solutions for (almost) everything - from backend to
          frontend.
        </div>
      </div>
    );
  }
}

export default About;
