import React, { Component } from "react";

class Skills extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col text-right">
            <ul>
              <li>C# / .NET / Core</li>
              <li>PHP</li>
              <li>JavaScript / TypeScript</li>
              <li>Angular</li>
              <li>React</li>
              <li>HTML / CSS</li>
              <li>SQL</li>
            </ul>
          </div>
          <div className="col text-left">
            <ul>
              <li>GIT / SVN</li>
              <li>WPF</li>
              <li>MVC / MVVM</li>
              <li>Windows Forms</li>
              <li>REST API</li>
              <li>CAN / J1939/UDS</li>
              <li>Hibernate / Entity Framework</li>
            </ul>
          </div>
          <div className="col text-left">
            <ul>
              <li>Microsoft Visual Studio</li>
              <li>Microsoft SQL Server</li>
              <li>MySQL</li>
              <li>Atlassian Jira</li>
              <li>Agile / Scrum</li>
              <li>GitBucket</li>
              <li>WCF</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Skills;
