import React, { Component } from "react";
import RotatingLogo from "./components/rotatingLogo";
import "./App.css";
import Contact from "./components/contact";
import Skills from "./components/skills";
import About from "./components/about";

class App extends Component {
  state = {
    infoText: "",
  };

  handleSkillClick = (arg) => {
    if (arg === "skills") {
      this.setState({ infoText: <Skills /> });
    } else if (arg === "about") {
      this.setState({ infoText: <About /> });
    } else if (arg === "contact") {
      this.setState({ infoText: <Contact /> });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="App-overlay">
          <div className="container innerMain">
            <div className="row">
              <div className="col">
                <RotatingLogo />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col text-right">
                <div
                  onClick={() => this.handleSkillClick("skills")}
                  className="infoBtn"
                >
                  Skills
                </div>
              </div>
              <div className="col">
                <div
                  onClick={() => this.handleSkillClick("about")}
                  className="infoBtn"
                >
                  About us
                </div>
              </div>
              <div className="col text-left">
                <div
                  onClick={() => this.handleSkillClick("contact")}
                  className="infoBtn"
                >
                  Contact
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="infoBox">{this.state.infoText}</div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
