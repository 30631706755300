import React, { Component } from "react";

class Contact extends Component {
  state = {};
  render() {
    return (
      <div>
        <div>info @ forit.se</div>
        <div>+46 (0) 70 603 04 18</div>
      </div>
    );
  }
}

export default Contact;
