import React, { Component } from "react";
import logo from "../images/forIT_no_icon.png";
import smallLogo from "../images/foritlogo.png";

class rotatingLogo extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-6 text-right app-logo-col">
          <img src={smallLogo} className="App-logo" alt="logo" />
        </div>
        <div className="col-6 app-logo-col text-left">
          <img src={logo} className="top-logo" alt="logo" />
        </div>
      </div>
    );
  }
}

export default rotatingLogo;
